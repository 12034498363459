import React from 'react';
import { FaPhone } from 'react-icons/fa';
import './contato.scss';

const Contato = () => {
    const infosContato = {
        AltPadrao: 'Companhia da vedação - vedações industriais',
        Titulo: 'Telefone',
        Endereco: 'Endereço'
    }
    return (
        <>
            <section className='Contato-wrapper'>
                <div className='Contato-info-wrapper'>
                    <div className='Contato-text-wrapper'>
                        <div className='contato-telefone-wrapper'>
                            <h4>{`${infosContato.Titulo}`}</h4>
                            <a href="tel:4130452542">
                                <span className='contato-icones'>
                                    <FaPhone/>
                                </span> 
                                (41) 3045-2542
                            </a>
                        </div>
                        <div className='Contato-endereco-wrapper'>
                            <h4>{`${infosContato.Endereco}`}</h4>
                            <a href="https://goo.gl/maps/6sK9UQytG1M2nhZT8" target="blank">
                                <p>
                                    Rua das Carmelitas, 3451 - Boqueirão - Curitiba -
                                    Paraná CEP 81730-050
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='Contato-maps-wrapper'>
                    <div className='Contato-image-wrapper'>
                        <iframe className='contato-mapa' 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.0726923683605!2d-49.2318978849844!3d-25.502622083754943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcfaee2e8bba51%3A0xf5b835df1845df13!2sCompanhia%20da%20Veda%C3%A7%C3%A3o!5e0!3m2!1spt-BR!2sbr!4v1662402283158!5m2!1spt-BR!2sbr"  
                            style={{border:'0'}} 
                            allowfullscreen="" 
                            loading="lazy"
                            title='MAPA'
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contato;