import React from "react";

import Head from '../Components/seo/Seo';
import Cabecalho from "../Components/header/Header";
import BannerManu from '../Components/BannerInfo02/BannerManutencao';
import Contato from "../Components/DadosDeContato/Dados";
import Footer from '../Components/footer/Rodape';

const ContatoPage = () => {

    const infoContato = {
        TituloSeo: 'Companhia da Vedação - Contato',
        Titulo: 'Contato',
        describe: 'Entre em contato conosco para obter mais informações sobre produtos e serviços.',
    }

    return (
        <>
            <Head Titulo={`${infoContato.TituloSeo}`}/>
            <Cabecalho LinkName={'Contato'}/>
            <BannerManu Titulo={`${infoContato.Titulo}`} Describe={`${infoContato.describe}`}/>
            <Contato/>
            <Footer/>
        </>
    )
}

export default ContatoPage;